import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PageTitle from "../../components/pagetitle"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMap, faEnvelope } from '@fortawesome/free-solid-svg-icons'

const ContactPage = () => (
  <Layout>
    <PageTitle title="Contact Us" page="PageTitleContact" />
    <Seo title="Contact Us"
         description="Contact us where we can assist in solving your data display and visualization technology challenges. We can help solve many app challenges." pathname={"/contact/main/"}/>
    <div className="content-page-wrapper">
        <div className="page-intro">
            <p >
                New Vertical Technologies offers business and residential computer services related to solving problems and ensuring access to data.
            </p>

            <p>
            <FontAwesomeIcon icon={faPhone} /> (410)266-8031<br />
            <FontAwesomeIcon icon={faMap} />
            <div>
                401 Log Canoe Cir<br/>
                Stevensville, MD 21666<br/>
                <Link style={{color:"blue"}} to="https://goo.gl/maps/jj8yPy39axuTAMCx7" target="_blank" >View Map</Link>
            </div>

            <FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:info@NewVerticalTech.com" style={{color:"blue"}}>info@NewVerticalTech.com</a><br />
            </p> 
        </div>
        <div className={"panel"}>
            <form action="https://getform.io/f/75b53c91-fa3b-450b-9a23-32946b3aeb48" method="POST">
                <h2>Contact Form</h2>
                <p>Please fill this form and press send.  Someone will be in touch with your shortly.</p>
                <div className={"form--group"}>
                    <label className={"form--label"}>Name</label>
                    <input type="text" name="name" className={"form--input"}/>
                </div>
                <div>
                    <label className={"form--label"}>Email</label>
                    <input type="email" name="email" className={"form--input"}/>
                </div>
                <div>
                    <label className={"form--label"}>Message</label>
                    <input type="text" name="message" className={"form--input"}/>
                </div>

                <input type="hidden" name="_gotcha" style={{display:"none !important"}} />
                <div>
                    <label className={"form--label"} htmlFor="">Subscribe</label>
                    <input className={"form--input-checkbox"} type="checkbox" name="subscribe" value="yes" checked />
                    <input type="hidden" name="subscribe" value="no" />
                </div>
                <button className={"btn btn--primary"} type="submit">Send</button>
            </form>
        </div>


    </div>        
  </Layout>
)

export default ContactPage
